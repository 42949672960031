import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import {useSelector} from "react-redux";
import {useEffect} from "react";

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {


    return useRoutes([MainRoutes, AuthenticationRoutes]);
}
