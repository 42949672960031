// material-ui
import {styled, useTheme} from '@mui/material/styles';
import {
    Avatar,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from '@mui/material';

// assets
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getCountedCart} from "../../../../views/utilities/Functions";

// styles
const ListItemWrapper = styled('div')(({theme}) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| CART PRODUCTS LIST ITEM ||============================== //

const CartProductsList = () => {
    const theme = useTheme();
    const cart = useSelector((state) => state.cart.items);
    const [countedCart, setCountedCart] = useState([]);

    useEffect(() => {
        setCountedCart(getCountedCart(cart))
    }, [cart]);

    const chipSX = {
        height: 24,
        padding: '0 6px',
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.light,
    };

    return (
        <List
            sx={{
                width: '100%',
                minWidth: 330,
                maxWidth: 530,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 500
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {countedCart.map((product) => {
                return (
                    <>
                        <ListItemWrapper>
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <Avatar alt={product.name} src={product.img}/>
                                </ListItemAvatar>
                                <ListItemText primary={product.name}/>
                                <ListItemSecondaryAction>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={12}>
                                            <Chip label={'x'+product.count} sx={chipSX}/>

                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Grid container direction="column" className="list-container">
                               {/* <Grid item xs={12} sx={{pb: 2}}>
                                    <Typography variant="subtitle2">{product.name}</Typography>
                                </Grid>*/}
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                {product.brand}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                        <Divider variant={"middle"}/>
                    </>
                )
            })}

        </List>
    );
};

export default CartProductsList;
