import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import apiBaseUrl from "../views/utilities/ApiBaseUrl";
import {CircularProgress, LinearProgress} from "@mui/material";
import {Box} from "@mui/system";

// const AuthContext = React.createContext({});

const useAuth = () => {
    return useContext(AuthContext);
}

const AuthContext = createContext({});

const AuthProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);


    //If there is a user already stored/logged in LocalStorage, lets use it
    useEffect(() => {
        setLoading(true);

        const loggedInUser = localStorage.getItem("ramisTheosUser");

        if (loggedInUser) {
            console.log('Found already logged user, restoring session...');

            const foundUser = JSON.parse(loggedInUser);
            setCurrentUser(foundUser);
        }
        setLoading(false);

    }, []);

    //Everytime user changes, we change our stored user
    useEffect(() => {
        if (currentUser) {
            localStorage.setItem('ramisTheosUser', JSON.stringify(currentUser));
        }
    }, [currentUser]);

    // useEffect(()=>{
    //     setContextValue({currentUser,
    //         login,
    //         signup,
    //         logout,
    //         resetPassword,
    //         updateEmail,
    //         updatePassword})
    // }, [currentUser]);

    async function signup(email, password, role) {
        try {

            let response = await fetch(apiBaseUrl + '/api/auth/register', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email: email, password: password, role: role}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            return {error: false};
        } catch (error) {
            console.error('Error: ', error);
            return {error: true, errorMessage: error.message}
        }
    }


    async function login(email, password) {
        try {
            // setLoading(true);

            const params = {
                email: email,
                password: password
            };


            // let response = await fetch(apiBaseUrl + '/ramis/usuario?name='+email+'&password='+password, {
            let response = await fetch(apiBaseUrl, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Target-URL': 'https://theos.mueblesramis.net:4433/ramis/usuario?'
                },
                body: JSON.stringify({name: email, password: password}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);


            if (response.acceso === "s") {
                setCurrentUser(response.usuario);
                setLoading(false);
            } else {
                const message = `Acceso denegado al usuario: ${response.usuario}`;
                throw new Error(message);
            }

            return {error: false, user: response.usuario};

        } catch (error) {
            console.error('Error: ', error);
            setLoading(false);
            return {error: true, errorMessage: error.message}
        }
    }


    const alternativeLogin = (user) => {
        setCurrentUser(user);
    }


    function logout() {
        setCurrentUser(undefined);
        localStorage.removeItem('ramisTheosUser');
    }

    async function resetPassword(email) {
        try {
            let response = await fetch(apiBaseUrl + '/api/auth/request-password-reset', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email: email}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            return {error: false};
        } catch (error) {
            console.error('Error: ', error);
            return {error: true, errorMessage: error.message}
        }
    }

    async function updateEmail(newEmail) {
        try {
            let response = await fetch(apiBaseUrl + '/api/auth/updateEmail', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({user_id: currentUser.id, new_email: newEmail}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            let updatedUser = JSON.parse(JSON.stringify(currentUser));
            updatedUser.email = newEmail;

            setCurrentUser(updatedUser);

            return {error: false};
        } catch (error) {
            console.error('Error: ', error);
            return {error: true, errorMessage: error.message}
        }
    }

    async function updatePassword(newPassword) {
        try {
            let response = await fetch(apiBaseUrl + '/api/auth/updatePassword', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({user_id: currentUser.id, password: newPassword}),
            })

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }
            response = await response.json();

            if (response.error) throw new Error(response.errorMessage);

            return {error: false};
        } catch (error) {
            console.error('Error: ', error);
            return {error: true, errorMessage: error.message}
        }
    }

    // useEffect(() => {
    //     const unsubscribe = auth.onAuthStateChanged((user) => {
    //         setCurrentUser(user);
    //         setLoading(false);
    //     });
    //
    //     return unsubscribe;
    // }, []);

    // Whenever the `value` passed into a provider changes,
    // the whole tree under the provider re-renders, and
    // that can be very costly! Even in this case, where
    // you only get re-renders when logging in and out
    // we want to keep things very performant.
    const memoedValue = useMemo(
        () => ({
            currentUser,
            setCurrentUser,
            login,
            alternativeLogin,
            signup,
            logout,
            resetPassword,
            updateEmail,
            updatePassword,
        }),
        [currentUser, loading]
    );

    return (
        <AuthContext.Provider value={memoedValue}>
            {!loading && children}
            {loading && <Box
                display="flex"
                width={"100%"}
                height={"100%"}
                alignItems="center"
                justifyContent="center"
                style={{backgroundColor: '#ffffff'}}
            >
                <LinearProgress variant={"indeterminate"} color={'primary'}/>
            </Box>}
        </AuthContext.Provider>
    );
}


export {AuthContext, AuthProvider, useAuth};
