// assets
import {IconDashboard, IconList, IconRobot, IconTable, IconCubeSend} from '@tabler/icons';
import {UnarchiveOutlined, UnarchiveRounded} from "@mui/icons-material";

// constant
const icons = {IconDashboard, IconList, IconRobot, IconTable, IconCubeSend};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const main = {
    id: 'sections',
    title: 'Secciones',
    type: 'group',
    children: [
        {
            id: 'main',
            title: 'Datos clientes',
            type: 'item',
            url: '/',
            icon: icons.IconList,
            breadcrumbs: false,
        }
    ]
};

export default main;
