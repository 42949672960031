import {lazy} from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";



const NotFound = Loadable(lazy(() => import('views/pages/not-found')));
const MainScreen = Loadable(lazy(() => import('views/pages/main')));



// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {

        path: '/',
        element: <MainLayout/>,
        children: [
            {
                path: '*',
                element: <NotFound/>
            },
            {
                path: '/',
                element: <MainScreen/>
            },

        ]
};

export default MainRoutes;
