import {useEffect, useRef, useState} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {
    Avatar, Badge,
    Box,
    Button,
    ButtonBase,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import CartProductsList from './CartProductsList';

// assets
import {IconShoppingCart} from '@tabler/icons';
import {useSelector} from "react-redux";
import {styled} from "@mui/system";
import {CheckRounded} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

// ==============================|| CART SECTION ||============================== //

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.warning.dark
    },
}));

const CartSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const cart = useSelector((state) => state.cart.items);
    const navigate = useNavigate();


    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => {
        if (event?.target.value) setValue(event?.target.value);
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{borderRadius: '12px'}}>
                    <StyledBadge
                        badgeContent={cart.length}
                    >
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            color="inherit"
                        >
                            <IconShoppingCart stroke={1.5} size="1.3rem"/>
                        </Avatar>
                    </StyledBadge>

                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({TransitionProps}) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow
                                          shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between"
                                                  sx={{pt: 2, px: 2}}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <IconShoppingCart />
                                                        <Typography variant="subtitle1">Your Shopping Cart</Typography>
                                                        <Chip
                                                            size="small"
                                                            label={cart.length + ' Items'}
                                                            sx={{
                                                                color: theme.palette.background.default,
                                                                bgcolor: theme.palette.warning.dark
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                {/*  <Grid item>
                                                    <Typography component={Link} to="#" variant="subtitle2" color="primary">
                                                        Mark as all read
                                                    </Typography>
                                                </Grid>*/}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{
                                                    height: '100%',
                                                    maxHeight: 'calc(100vh - 205px)',
                                                    overflowX: 'hidden'
                                                }}
                                            >
                                                <CartProductsList/>
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>
                                    <Divider/>
                                    <CardActions sx={{p: 1.25, justifyContent: 'center'}}>
                                        <Button onClick={()=> navigate('eCommerce/cart')} size="medium" variant={"contained"} sx={{borderRadius: 40}} disableElevation startIcon={<CheckRounded/>}>
                                            Proceed to Checkout
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default CartSection;
